import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["dropdown", "hamburger", "closeButton"];

  open() {
    document.body.style.overflow = "hidden";
    this.dropdownTarget.classList.remove("hidden");
    this.dropdownTarget.classList.add("flex");
    this.hamburgerTarget.classList.add("hidden");
    this.closeButtonTarget.classList.remove("hidden");
  }

  close() {
    document.body.style.overflow = "visible";
    this.dropdownTarget.classList.remove("flex");
    this.dropdownTarget.classList.add("hidden");
    this.hamburgerTarget.classList.remove("hidden");
    this.closeButtonTarget.classList.add("hidden");
  }
}
