import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [];

  open(event) {
    this.resetDropMenu();
    const target = document.getElementById(
      `${event.currentTarget.id}-dropdown`
    );
    const content = document.getElementById(
      `${event.currentTarget.id}-content`
    );
    const icon = document.getElementById(`${event.currentTarget.id}-icon`);
    const buttons = document.getElementById("fixed-buttons");

    document.body.style.overflow = "hidden";
    target.classList.remove("scale-y-0");
    target.classList.add("scale-y-1", "open");
    icon.classList.add("rotate-180");
    content.classList.remove("opacity-0", "delay-100");
    content.classList.add("opacity-1", "delay-350");
    buttons.classList.remove("opacity-1");
    buttons.classList.add("opacity-0");
    event.currentTarget.dataset.action = "click->dropdown#close";
  }

  close(event) {
    const target = document.getElementById(
      `${event.currentTarget.id}-dropdown`
    );
    const content = document.getElementById(
      `${event.currentTarget.id}-content`
    );
    const icon = document.getElementById(`${event.currentTarget.id}-icon`);
    const buttons = document.getElementById("fixed-buttons");

    document.body.style.overflow = "visible";
    target.classList.remove("scale-y-1", "delay-100", "open");
    target.classList.add("scale-y-0");
    icon.classList.remove("rotate-180");
    content.classList.remove("opacity-1", "delay-350");
    content.classList.add("opacity-0", "delay-100");
    buttons.classList.remove("opacity-0");
    buttons.classList.add("opacity-1");
    event.currentTarget.dataset.action = "click->dropdown#open";
  }

  show(event) {
    const target = document.getElementById(`${event.currentTarget.id}-img`);

    target.classList.remove("opacity-0");
    target.classList.add("opacity-1");
  }

  hide(event) {
    const target = document.getElementById(`${event.currentTarget.id}-img`);

    target.classList.remove("opacity-1");
    target.classList.add("opacity-0");
  }

  resetDropMenu() {
    const target = document.querySelector(".open");

    if (target) {
      const icon = document.getElementById(`${target.id.split("-")[0]}-icon`);
      const buttons = document.getElementById("fixed-buttons");

      target.classList.remove("scale-y-1", "open");
      target.classList.add("scale-y-0");
      icon.classList.remove("rotate-180");
      buttons.classList.remove("opacity-0");
      buttons.classList.add("opacity-1");
      icon.parentElement.dataset.action = "click->dropdown#open";
    }
  }
}
