import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["addService", "template"];

  new() {
    const id = new Date().valueOf();
    const content = this.templateTarget.innerHTML.replace(/TEMPLATE_ID/g, id);

    this.addServiceTarget.insertAdjacentHTML("beforebegin", content);
  }

  destroy(event) {
    const id = event.currentTarget.dataset.formId;
    const form = document.getElementById(`${id}`);

    form.querySelector("input[name*='_destroy']").value = 1;
    form.classList.add("hidden");
  }
}
