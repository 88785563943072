import { Controller } from "@hotwired/stimulus";
import Rails from "@rails/ujs";
import Sortable from "sortablejs";

export default class extends Controller {
  connect() {
    this.sortable = Sortable.create(this.element, {
      onEnd: this.drop.bind(this),
    });
  }

  drop(event) {
    let id = event.item.dataset.id;
    let url = this.data.get("url").replace(":id", id);
    let data = new FormData();
    data.append("position", event.newIndex + 1);

    Rails.ajax({
      url: url,
      type: "PATCH",
      data: data,
    });
  }
}
