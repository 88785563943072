import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [];

  preview(event) {
    const input = event.target;

    if (input.files && input.files[0]) {
      const preview = document.getElementById(`${input.id}_preview`);

      preview.src = URL.createObjectURL(input.files[0]);
      preview.classList.remove("hidden");
    }
  }
}
