import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["slideMobile", "slideDesktop"];

  initialize() {
    this.slideIndex = 0;
    this.target = this.setTarget();
    this.length = this.target.length;
    this.target[this.slideIndex].classList.remove("hidden", "opacity-0", "z-0");
    this.target[this.slideIndex].classList.add("flex", "opacity-1");
  }

  resetSlides() {
    for (let i = 0; i < this.length; i++) {
      if (this.target[i].classList.contains("opacity-1")) {
        this.target[i].classList.remove("flex", "opacity-1");
        this.target[i].classList.add("hidden", "opacity-0", "z-0");
      }
    }
  }

  moveSlide() {
    this.target[this.slideIndex].classList.remove("hidden", "opacity-0", "z-0");
    this.target[this.slideIndex].classList.add("flex", "opacity-1");
  }

  next() {
    this.resetSlides();
    this.slideIndex =
      this.slideIndex < this.length - 1 ? this.slideIndex + 1 : 0;
    this.moveSlide();
  }

  previous() {
    this.resetSlides();
    this.slideIndex =
      this.slideIndex > 0 ? this.slideIndex - 1 : this.length - 1;
    this.moveSlide();
  }

  setTarget() {
    if (window.screen.width < 768) {
      return this.slideMobileTargets;
    } else {
      return this.slideDesktopTargets;
    }
  }
}
