import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [];

  open(event) {
    const content = document.getElementById(
      `${event.currentTarget.id}-content`
    );
    event.target.classList.add("rotate-180");
    content.classList.remove("hidden");
    event.currentTarget.dataset.action = "accordion#close";
  }

  close(event) {
    const content = document.getElementById(
      `${event.currentTarget.id}-content`
    );
    event.target.classList.remove("rotate-180");
    content.classList.add("hidden");
    event.currentTarget.dataset.action = "accordion#open";
  }
}
