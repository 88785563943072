import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [];

  initialize() {
    this.slideIndex = 0;
    this.invalidID;
    this.startCarousel();
  }

  resetSlides() {
    for (let i = 0; i < this.element.children.length; i++) {
      if (this.element.children[i].classList.contains("opacity-1")) {
        this.element.children[i].classList.remove("opacity-1");
        this.element.children[i].classList.add("opacity-0", "z-0");
      }
    }
  }

  moveSlides() {
    this.element.children[this.slideIndex].classList.remove("opacity-0", "z-0");
    this.element.children[this.slideIndex].classList.add("opacity-1");
  }

  startCarousel() {
    this.intervalID = setInterval(() => {
      this.slideIndex = this.slideIndex < 2 ? this.slideIndex + 1 : 0;
      this.resetSlides();
      this.moveSlides();
    }, 4000);
  }

  next() {
    clearInterval(this.intervalID);
    this.slideIndex = this.slideIndex < 2 ? this.slideIndex + 1 : 0;
    this.resetSlides();
    this.moveSlides();
    this.startCarousel();
  }

  previous() {
    clearInterval(this.intervalID);
    this.slideIndex = this.slideIndex > 0 ? this.slideIndex - 1 : 2;
    this.resetSlides();
    this.moveSlides();
    this.startCarousel();
  }
}
