import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["tab", "content", "title"];

  toggle(event) {
    const content = document.getElementById(
      `${event.currentTarget.id}-content`
    );
    this.resetContent();
    this.resetTabs(event);
    event.currentTarget.classList.remove("border-black");
    event.currentTarget.classList.add(
      "bg-primary",
      "border-primary",
      "text-white"
    );
    content.classList.remove("hidden");
    this.titleTarget.innerText = this.capitalize(event.currentTarget.id);
  }

  resetTabs() {
    this.tabTargets.forEach((element) => {
      if (element.classList.contains("bg-primary")) {
        element.classList.remove("bg-primary", "border-primary", "text-white");
        element.classList.add("border-black");
      }
    });
  }

  resetContent() {
    this.contentTargets.forEach((element) => {
      if (!element.classList.contains("hidden")) {
        element.classList.add("hidden");
      }
    });
  }

  capitalize(word) {
    return word[0].toUpperCase() + word.slice(1).toLowerCase();
  }
}
