import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [];

  initialize() {
    this.navigation = document.getElementById("navbar");
    this.buttons = document.getElementById("fixed-buttons");
  }

  open(event) {
    let target;

    if (window.screen.width < 768) {
      target = document.getElementById(`${event.currentTarget.id}-info-mobile`);
    } else {
      target = document.getElementById(`${event.currentTarget.id}-info`);
    }

    this.navigation.classList.add("hidden");
    this.buttons.classList.remove("lg:block");
    document.body.style.overflow = "hidden";
    target.classList.remove("hidden");
    target.classList.add("flex");
  }

  close(event) {
    let target;

    if (window.screen.width < 768) {
      target = document.getElementById(`${event.currentTarget.id}-info-mobile`);
    } else {
      target = document.getElementById(`${event.currentTarget.id}-info`);
    }

    this.navigation.classList.remove("hidden");
    this.buttons.classList.add("lg:block");
    document.body.style.overflow = "visible";
    target.classList.remove("flex");
    target.classList.add("hidden");
  }
}
