import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["controls"];

  initialize() {
    this.slideIndex = 0;
    this.intervalID;
    this.startCarousel();
  }

  resetSlides() {
    for (let i = 0; i < this.element.children.length; i++) {
      if (this.element.children[i].classList.contains("opacity-1")) {
        this.element.children[i].classList.remove("opacity-1");
        this.element.children[i].classList.add("opacity-0", "z-0");
      }
    }
  }

  moveSlides() {
    this.element.children[this.slideIndex].classList.remove("opacity-0", "z-0");
    this.element.children[this.slideIndex].classList.add("opacity-1");
  }

  startCarousel() {
    this.intervalID = setInterval(() => {
      this.slideIndex = this.slideIndex < 2 ? this.slideIndex + 1 : 0;
      this.resetSlides();
      this.moveSlides();
      this.moveControls();
    }, 4000);
  }

  showSlide(event) {
    clearInterval(this.intervalID);
    this.slideIndex = parseInt(event.currentTarget.dataset.index);
    this.resetSlides();
    this.moveSlides();
    this.moveControls();
    this.startCarousel();
  }

  moveControls() {
    this.controlsTargets.forEach((element) => {
      if (element.classList.contains("bg-primary")) {
        element.classList.remove("bg-primary");
        element.classList.add("bg-gray-300");
      }
    });
    this.controlsTargets[this.slideIndex].classList.remove("bg-gray-300");
    this.controlsTargets[this.slideIndex].classList.add("bg-primary");
  }
}
